<template>
  <v-container fluid>
    <v-alert type="success" v-if="points > 0" dismissible>
      {{ $t('you_have_earned') }} <b>{{points}} {{ $t('points') }}</b> {{ $t('for_video_watched') }}
    </v-alert>
    <v-container fluid>
      <p class="font-weight-medium">{{ $t('earn_points_by_watching_videos') }}</p>

      <div v-if="multimedias.length == 0 && $store.state.loader == false">
        <p class="font-weight-medium">{{ $t('no_video_available') }}</p>
      </div>

      <v-row dense v-if="multimedias.length > 0">
        <v-col v-for="(multimedia, idx) in multimedias" :key="idx" :cols="12">
          <v-card>
            <v-img
              :src="multimedia.thumbnail ? multimedia.thumbnail : `https://picsum.photos/id/${getRandomInt(1000)}/300`"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
              width="100%"
              @click="$router.push(`/Multimedia/View/${multimedia._id}`)"
            />

            <v-card-actions>
              <v-btn text color="primary" @click="$router.push(`/Multimedia/View/${multimedia._id}`)">
                {{ $t('watch') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import { DataService } from "@/services/DataService";
const dataService = new DataService();
export default {
  data() {
    return {
      multimedias: [],
      loading: false,
      points: 0
    };
  },
  mounted() {
    if (this.$route.query.points) {
      this.points = this.$route.query.points;
    }
    this.$store.state.loader = true
    dataService.listMultimedia(this.$store.state.token, { userId: this.$store.state.userProfile._id }).then(m => {
      this.multimedias = m.result;
      this.$store.state.loader = false
    });
  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    }
  }
};
</script>